import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { LandingPageComponent } from './keycloak/landing-page/landing-page.component';
import { CallbackComponent } from './keycloak/callback/callback.component';
// import { AgentAuthGuard } from './guards/agent-auth.guard';
// import { BdexAuthGuard } from './guards/bdex-auth.guard';
import { LocalStorageComponent } from './common/local-storage/local-storage.component';
import { RoleGuardGuard } from './guards/role-guard.guard';
import { MainShellComponent } from './common/main-shell/main-shell.component';
import { UserInfoResolver } from './shared/services/user-info.resolver';


const routes: Routes = [
  {
    path: '',
    component: MainShellComponent,
    resolve: { userInfo: UserInfoResolver },
    canActivate: [AuthGuard],
    children: [
      {
        path: 'bdex-dashboard',
        loadChildren: () => import('./bdex-dashboard/bdex-dashboard.module').then(m => m.BdexDashboardModule),
        canActivate: [RoleGuardGuard],
        data: { preload: true, delay: 3000 , roles: ['BDEX']}
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [RoleGuardGuard],
        data: { preload: true, delay: 3000 , roles: ['ADMIN']}
      },
      {
        path: 'agent-dashboard',
        loadChildren: () => import('./agent-dashboard/agent-dashboard.module').then(m => m.AgentDashboardModule),
        canActivate: [RoleGuardGuard],
        data: { preload: true, delay: 3000 , roles: ['AGENT']},
      },
      {
        path: 'maintainer-dashboard',
        loadChildren: () => import('./maintainer-dashboard/maintainer-dashboard.module').then(m => m.MaintainerDashboardModule),
        canActivate: [RoleGuardGuard],
        data: { preload: true, delay: 3000 , roles: ['STATEMENT MAINTAINER']},
      },
      {
        path: 'super-admin-dashboard',
        loadChildren: () => import('./super-admin-dashboard/super-admin-dashboard.module').then(m => m.SuperAdminDashboardModule),
        canActivate: [RoleGuardGuard],
        data: { preload: true, delay: 3000 , roles: ['SUPER-ADMIN']},
      },
      {
        path: 'operation-team-dashboard',
        loadChildren: () => import('./operation-team-dashboard/operation-team-dashboard.module').then(m => m.OperationTeamDashboardModule),
        canActivate: [RoleGuardGuard],
        data: { preload: true, delay: 3000 , roles: ['OPERATION-TEAM']}
      },
      {
        path: 'it-team-dashboard',
        loadChildren: () => import('./it-team-dashboard/it-team-dashboard.module').then(m => m.ItTeamDashboardModule),
        canActivate: [RoleGuardGuard],
        data: { preload: true, delay: 3000 , roles: ['IT-TEAM']},
      },
      {
        path: 'operation-admin-dashboard',
        loadChildren: () => import('./operation-admin-dashboard/operation-admin-dashboard.module').then(m => m.OperationAdminDashboardModule),
        canActivate: [RoleGuardGuard],
        data: { preload: true, delay: 3000 , roles: ['OPERATION-ADMIN']}
      },
      {
        path: 'maker-dashboard',
        loadChildren: () => import('./maker-dashboard/maker-dashboard.module').then(m => m.MakerDashboardModule),
        canActivate: [RoleGuardGuard],
        data: { preload: true, delay: 3000 , roles: ['MAKER']}
      },
      {
        path: 'checker-dashboard',
        loadChildren: () => import('./checker-dashboard/checker-dashboard.module').then(m => m.CheckerDashboardModule),
        canActivate: [RoleGuardGuard],
        data: { preload: true, delay: 3000 , roles: ['CHECKER']}
      },
      {
        path: 'acquisition-team-dashboard',
        loadChildren: () => import('./acquisition-team-dashboard/acquisition-team-dashboard.module').then(m => m.AcquisitionTeamDashboardModule),
        canActivate: [RoleGuardGuard],
        data: { preload: true, delay: 3000 , roles: ['ACQUISITION-TEAM']}
      },
      { 
        path: '', 
        redirectTo: '/login', 
        pathMatch: 'full' 
      },
      {
        path: 'not-found',
        loadChildren: () => import("./modules/page-not-found/page-not-found.module").then(m => m.PageNotFoundModule),
      },
      {
        path: 'access-denied',
        loadChildren: () => import("./modules/access-denied/access-denied.module").then(m => m.AccessDeniedModule),
      }
    ]
  },
  { 
    path: 'login',
    loadChildren: () => import('./login/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'session-expired',
    loadChildren: () => import("./modules/session-expired/session-expired.module").then(m => m.SessionExpiredModule),
  },
  { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


