import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LogoutRequest, LogoutResponse } from '../request/logout-request';
import { api } from '../constant/api';


@Injectable({
  providedIn: 'root'
})
export class LogoutService {


  constructor(private httpClient: HttpClient) { }

    loggingOut(loginRequest: LogoutRequest): Observable<LogoutResponse> {      
      return this.httpClient.post<LogoutResponse>(`${environment.baseURL}${api.userLogout}`, loginRequest);
    }

}
