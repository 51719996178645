import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AppStorageService } from 'src/app/login/auth/app-storage.service';
import { AuthService } from 'src/app/login/auth/auth.service';
import { ChangingUserPasswordDialogComponent } from '../changing-user-password-dialog/changing-user-password-dialog.component';
import { language, loginCookieStoreKey } from '../constant/constant';
import { Header } from '../request/base-request';
import { LogoutRequest, LogoutRequestBody, LogoutRequestHeader } from '../request/logout-request';
import { LogoutService } from '../services/logout.service';
import { TitleService } from '../services/title.service';
import { SidebarService } from '../sidebar/sidebar.service';
declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;

    if (this.innerWidth < 900) {
      this.sidebarservice.setShow();
    } else {
      this.sidebarservice.setHidden();
    }


  }
  name: string;

  title: String;
  public menus: {};

  constructor(public sidebarservice: SidebarService,
    private _authService: AuthService,
    private titleService: TitleService,
    private _appStorageService: AppStorageService,
    private dialog: MatDialog,
    private http: HttpClient,
    private logoutService: LogoutService,
    private translate: TranslateService) {
      let lan = this._appStorageService.getData(language);
    // if (lan) {
    //   translate.use(lan);
    // } else {
    //   translate.setDefaultLang('bn');
    //   this._appStorageService.setData(language, 'bn');
    // }
  }

  changeLanguage(lan: string) {
    this.translate.use(lan);
    this.setActiveLanguageLink();
  }

  setActiveLanguageLink() {
    if (this.translate.currentLang === 'en') {
      $('#englishLangLink').addClass("active");
      $('#banglaLangLink').removeClass("active");
      document.documentElement.setAttribute('lang', 'en');
    }
    else if (this.translate.currentLang === 'bn') {
      $('#banglaLangLink').addClass("active");
      $('#englishLangLink').removeClass("active");
      document.documentElement.setAttribute('lang', 'bn');
    }
  }
  public innerWidth: any;
  public userInfo: any;
  ngOnInit(): void {
    let lan = this._appStorageService.getData(language);
      // console.log(this.translate.currentLang);
      if (lan) {
        this.translate.use(lan);
      } else {
        this.translate.setDefaultLang('bn');
        this._appStorageService.setData(language, 'bn');
      }

    this.innerWidth = window.innerWidth;
    this.titleService.getTitle().subscribe(appTitle => this.title = appTitle);
    this.userInfo = this._appStorageService.getData(loginCookieStoreKey);
    // this.getChatBotInformation();

    if (this._authService.currentAgentName != "null") {
      this.name = this._authService.currentAgentName;

    } else {
      this.name = this._authService.currentEmployeeName;
    }
  }

  sendDataToRasa() {
    const endpoint = 'http://localhost:5005/webhooks/rest/webhook';
    this.http.post(endpoint, {name: this.userInfo}).subscribe(response => {
      console.log(response);
    });
  }


  public window: any;
  getChatBotInformation() {

    let e = document.createElement("script"),
      t = document.head || document.getElementsByTagName("head")[0];
    (e.src =
      "https://cdn.jsdelivr.net/npm/rasa-webchat@1.x.x/lib/index.js"),
      // Replace 1.x.x with the version that you want
      (e.async = !0),
      (e.onload = () => {
        window['WebChat'].default(
          {
            title: "Agrani Doer",
            initPayload: ['/ques_0{"agentname": "khan"}'].join(''),
            customData: { language: "en", agentName: this.name },
            socketUrl: "http://localhost:5005",
            inputTextFieldHint: "Type a message",
            showMessageDate: true,
            displayUnreadCount: true
            // socketPath: "/socket.io/",
            // profileAvatar: 'logo.jpeg',
            // openLauncherImage: 'logo.jpeg',
            // params: {storage: "session"},
            // add other props here
          },
          null
        );
        this.window = window;
      }),
      t.insertBefore(e, t.firstChild);
      e.setAttribute("id", "Chat-Bot");
      this.sendDataToRasa();
  }


  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  toggleBackgroundImage() {
    this.sidebarservice.hasBackgroundImage = !this.sidebarservice
      .hasBackgroundImage;
  }

  ngAfterViewInit() {
    setTimeout(() => this.setActiveLanguageLink());
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }

  request: LogoutRequest = new LogoutRequest();
  myHeader: LogoutRequestHeader = new LogoutRequestHeader();
  requestBody: LogoutRequestBody = new LogoutRequestBody();

  header: Header = new Header();

  logout(): void {
    this.myHeader.requestId = this.header.requestId;
    this.myHeader.requestDateTime = this.header.requestDateTime;
    this.myHeader.requestSource = this.header.requestSource;
    this.myHeader.requestServiceSource = this.header.requestServiceSource;

    this.request.body = this.requestBody;
    this.request.header = this.myHeader;

    this.logoutService.loggingOut(this.request).subscribe(i=> {
      this._authService.logout();
    });
    
    // document.getElementById('rasaWebchatPro').remove();
    // document.getElementsByName("script").remove();
  }


  changingPassword() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.disableClose = true;
    dialogConfig.width = "510px";
    dialogConfig.height = "auto";
    dialogConfig.data = {};

    this.dialog.open(ChangingUserPasswordDialogComponent, dialogConfig).afterClosed().subscribe(res => {
      if (res === 'Yes') {
      }
    });
  }


}


