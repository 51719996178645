
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { AuthService } from '../login/auth/auth.service';


@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    // this.router.navigate(['/login']);
    if (!this.authService.isUserLoggedIn()) {
      this.router.navigate(["login"]);
      return false;
    }
    return true;
  }
}

// export class AuthGuard implements CanActivate {
//   constructor(private router: Router, private authService: AuthService) { }

//   canActivate(): boolean {
//     if (this.authService.isAdmin()) {
//       return true;
//     } else {
//       console.warn('Auth guard bloked request. Please sign in');
//       this.router.navigate(['/home']);
//       return false;
//     }
//   }
// }
