import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/login/auth/auth.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  // public login() {
  //   this.authService.login();
  // }

  // public logout() {
  //   this.authService.logout();
  // }

  // public isLoggedIn() {
  //   return this.authService.isLoggedIn();
  // }

}
