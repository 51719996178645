import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Header } from 'src/app/common/request/base-request';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { MainShellComponent } from 'src/app/common/main-shell/main-shell.component';
import { AuthService } from 'src/app/login/auth/auth.service';
import { LoginRequest } from 'src/app/login/shared/model/login-request';
import { LoginRequestBody } from 'src/app/login/shared/model/login-request-body';
import { LoginRequestHeader } from 'src/app/login/shared/model/login-request-header';
import { UserInfoService } from 'src/app/login/shared/services/user-info.service';

@Injectable({
  providedIn: 'root',
})
export class UserInfoResolver implements Resolve<MainShellComponent>{
 
  constructor(private _authService: AuthService, private _userInfoService: UserInfoService) { }

  loginRequestBody: LoginRequestBody = new LoginRequestBody();
  loginRequest: LoginRequest = new LoginRequest();
  loginRequestHeader: LoginRequestHeader = new LoginRequestHeader();

  header: Header = new Header();

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    this.loginRequestHeader.requestId = this.header.requestId;
    this.loginRequestHeader.requestDateTime = this.header.requestDateTime;
    this.loginRequestHeader.requestSource = this.header.requestSource;
    this.loginRequestHeader.requestServiceSource = this.header.requestServiceSource;

    this.loginRequest.body = this.loginRequestBody;
    this.loginRequest.header = this.loginRequestHeader;

    return this._userInfoService.getUserInfo(this.loginRequest).pipe(
      catchError((error) => {
        this._authService.logout();
        return of(null);
      })
  );
  }

  

}
