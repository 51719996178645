
export class LogoutRequest {
    header: LogoutRequestHeader;
    body: LogoutRequestBody;
}

export class LogoutRequestHeader {
    requestId: String;
    requestDateTime: String;
    requestSource: String;
    requestServiceSource: String
}

export class LogoutRequestBody {
    
}

export class LogoutResponseHeader {
    requestId: String;
    requestDateTime: Date;
    responseDateTime: Date;
    responseCode: String;
    remarks: String;
    status: String;
    message: string;
}

export class LogoutResponseBody {
    oid: string;  
}

export class LogoutResponse {
    header: LogoutResponseHeader;
    body: LogoutResponseBody;
}