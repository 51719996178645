import { HttpHeaders } from "@angular/common/http";

export const menuJsonCookieStoreKey = 'login.doererp.cookie.store.menujson.key';
export const loginCookieStoreKey = 'login.doererp.cookie.store.key';
export const userNameStoreKey = 'login.doererp.cookie.store.username.key';
export const mobileNoStoreKey = 'login.doererp.cookie.store.mobileNo.key';
export const employeeName = 'login.doererp.cookie.store.employeeName.key';
export const employeeId = 'login.doererp.cookie.store.employeeId.key';
export const employeeOid = 'login.doererp.cookie.store.employeeOid.key';
export const userIdStoreKey = 'login.doererp.cookie.store.userid.key';
export const roleIdStoreKey = 'login.doererp.cookie.store.roleoid.key';
export const emailStoreKey = 'login.doererp.cookie.store.email.key';
export const branchStoreKey = 'login.doererp.cookie.store.branch.key';
export const branchOidStoreKey = 'login.doererp.cookie.store.branchoid.key';
export const cspStoreKey = 'login.doererp.cookie.store.csp.key';
export const cspOidStoreKey = 'login.doererp.cookie.store.cspoid.key';
export const loginOidStoreKey = 'login.doererp.cookie.store.loginoid.key';
export const clusterOidStoreKey = 'login.doererp.cookie.store.clusteroid.key';
export const agentClusterOidStoreKey = 'login.doererp.cookie.store.agentClusterOid.key';
export const CLIENT_ID = '3C:A9:F4:34:02:B8';
export const APPLICATION_NAME = 'DOER-ERP';
export const APPLICATION_SERVICE_NAME = 'DOER-ERP-Portal';
export const CLIENT_NAME = 'doer';
export const REQUEST_VERSION = '1.0';
export const REQUEST_TIMEOUT_IN_SECONDS = 120;
export const PAGE_SIZE = 10;
export const JPG_EXTENTION = '.jpg';
export const LANGUAGE_BANGLA = 'বাংলা';
export const LANGUAGE_ENGLISH = 'English';
export const USER_IMG_PATH = 'assets/images/photo/user/';
export const imagePath = 'assets/images/photo/';
export const PAGE_SIZE_OPTIONS = [5, 10, 25, 50, 100];
export const STATUS_ACTIVE = 'Active';
export const STATUS_LOADED = 'Loaded';
export const STATUS_SEND_SMS = 'Sent';
export const STATUS_VALID = 'Valid';
export const STATUS_INVALID = 'Invalid';
export const mobileNoRegEx = '^(0|[0+]880)1[3-9][0-9]{8}$';
export const EmailRegEx = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';
export const YES = 'Yes';
export const NO = 'No';
export const REPORT_TYPE_PDF = 'pdf';
export const REPORT_TYPE_CSV = 'csv';
export const REPORT_TYPE_XLS = 'xls';
export const REPORT_TYPE_ZIP = 'zip';
export const STATUS_NOT_BATCHED = 'notBatched';
export const STATUS_CREATED = 'created';
export const STATUS_BATCHED = 'batched';
export const STATUS_SUBMITTED = 'submitted';
export const STATUS_PRE_REVIEWING = 'preReviewing';
export const STATUS_REVIEWING = 'reviewing';
export const STATUS_REVIEWED = 'reviewed';
export const STATUS_APPROVED = 'approved';
export const INVALID_TOKEN = '401';


export const agentName = 'login.doererp.cookie.store.agentName.key';
export const clusterName = 'login.doererp.cookie.store.clusterName.key';
export const agentClusterName = 'login.doererp.cookie.store.agentClusterName.key';
export const tradeName = 'login.doererp.cookie.store.tradeName.key';
export const language = 'login.doererp.cookie.store.language.key';

export const AUTH_STORE_KEY = 'login.doererp.cookie.store.accesstoken.key';
export const CONTENT_TYPE = 'application/json';

export function getHttpHeaders(): HttpHeaders {
    return new HttpHeaders()
        .set('content-type', CONTENT_TYPE)
        .set('accept', CONTENT_TYPE);
}


