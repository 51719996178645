import { AfterViewInit, Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SidebarService } from './sidebar.service';
import { agentClusterName, agentClusterOidStoreKey, agentName, branchOidStoreKey, branchStoreKey, clusterName, clusterOidStoreKey, cspOidStoreKey, cspStoreKey, emailStoreKey, employeeId, employeeName, employeeOid, language, loginCookieStoreKey, loginOidStoreKey, menuJsonCookieStoreKey, mobileNoStoreKey, roleIdStoreKey, tradeName, userIdStoreKey, userNameStoreKey } from '../constant/constant';
import { AppStorageService } from 'src/app/login/auth/app-storage.service';
import { AuthService } from 'src/app/login/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('slide', [
      state('up', style({ height: 0 })),
      state('down', style({ height: '*' })),
      transition('up <=> down', animate(200))
    ])
  ]
})
export class SidebarComponent implements OnInit, AfterViewInit {

  public menus: Object[];
  public userName$ : any;
  public roleOid$ : any;

  name:string;

  public showSideBar:boolean =  false;

  constructor(public sidebarservice: SidebarService,
             private _appStorageService: AppStorageService,
             private _authService: AuthService,
             private translate: TranslateService,
             private route: ActivatedRoute,
             private authService: AuthService,
             private _router: Router) {
                 
              let lan = this._appStorageService.getData(language);
              // if (lan) {
              //   translate.use(lan);
              // } else {
              //   translate.setDefaultLang('bn');
              //   this._appStorageService.setData(language, 'bn');
              // }
   }

   changeLanguage(lan: string) {
    this.translate.use(lan);
    this.setActiveLanguageLink();
  }

setActiveLanguageLink() {
    if (this.translate.currentLang === 'en') {
        $('#englishLangLink').addClass("active");
        $('#banglaLangLink').removeClass("active");
        document.documentElement.setAttribute('lang', 'en');
    }
    else if (this.translate.currentLang === 'bn') {
        $('#banglaLangLink').addClass("active");
        $('#englishLangLink').removeClass("active");
        document.documentElement.setAttribute('lang', 'bn');
    }
}

  ngOnInit() {
    
    this.userName$ = this._authService.currentUserName;
    this.roleOid$ = this._authService.currentRoleOid;

    

    if (this.route.snapshot.data["userInfo"]) {
      this.showSideBar = true;
      this.route.data
        .subscribe(data => {
            if (data['userInfo']) {
              let resData = data['userInfo'];
              if(resData.header.responseCode && resData.header.responseCode != '200'){
                this._authService.logout();
              }
              console.log(resData.body);
              this.menus = JSON.parse(resData.body.menuJson);
              this._appStorageService.setData(menuJsonCookieStoreKey, resData.body.menuJson);
              this._appStorageService.setData(userIdStoreKey, resData.body.agentId);
              this._appStorageService.setData(userNameStoreKey, resData.body.userName);
              this._appStorageService.setData(mobileNoStoreKey, resData.body.agentMobileNo ? resData.body.agentMobileNo : resData.body.mobileNo);
              this._appStorageService.setData(roleIdStoreKey, resData.body.roleOid);
              this._appStorageService.setData(emailStoreKey, resData.body.email ? resData.body.email : resData.body.empEmail);
              this._appStorageService.setData(branchStoreKey, resData.body.branchName);
              this._appStorageService.setData(cspStoreKey, resData.body.cspName);
              this._appStorageService.setData(loginOidStoreKey, resData.body.oid);
              this._appStorageService.setData(clusterOidStoreKey, resData.body.clusterOid);
              this._appStorageService.setData(agentClusterOidStoreKey, resData.body.agentClusterOid);
              this._appStorageService.setData(branchOidStoreKey, resData.body.branchOid);
              this._appStorageService.setData(cspOidStoreKey, resData.body.cspOid);
              this._appStorageService.setData(employeeName, resData.body.employeeName);
              this._appStorageService.setData(employeeId, resData.body.employeeId);
              this._appStorageService.setData(employeeOid, resData.body.employeeOid);
              this._appStorageService.setData(agentName, resData.body.agentName);
              this._appStorageService.setData(clusterName, resData.body.clusterName);
              this._appStorageService.setData(agentClusterName, resData.body.agentClusterName);
              this._appStorageService.setData(tradeName, resData.body.tradeName);

              if (this._authService.currentAgentName != "null") {
                this.name = this._authService.currentAgentName;
              } else {
                this.name = this._authService.currentEmployeeName;
              }
            }
        });
      }

  }
  

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  toggle(currentMenu) {
    if (currentMenu.type === 'dropdown') {
      this.menus.forEach(element => {
        if (element === currentMenu) {
          currentMenu.active = !currentMenu.active;
        } else {
          //element.active = false;
        }
      });
    }
  }

  getState(currentMenu) {

    if (currentMenu.active) {
      return 'down';
    } else {
      return 'up';
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.setActiveLanguageLink());
}

  hasBackgroundImage() {
    return this.sidebarservice.hasBackgroundImage;
  }

  logout(): void {
    this._authService.logout();
  }

}
