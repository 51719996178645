import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChangingUserPasswordRequest } from '../models/changing-user-password/changing-user-password-request';
import { ChangingUserPasswordResponse } from '../models/changing-user-password/changing-user-password-response';
import { DashboardRequest } from '../models/dashboard-request';
import { DashboardResponse } from '../models/dashboard-response';
import { api } from 'src/app/common/constant/api';

@Injectable({
  providedIn: 'root'
})
export class ChangingUserPasswordService {

  constructor(private httpClient: HttpClient) { }

  changingPassword(changingUserPasswordRequest: ChangingUserPasswordRequest): Observable<ChangingUserPasswordResponse> {
    return this.httpClient.post<ChangingUserPasswordResponse>(`${environment.baseURL}${api.updateUserPasswordByLoginOid}`, changingUserPasswordRequest);
  }
}
