import { Injectable, Injector } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';

import { tap, catchError, switchMap } from 'rxjs/operators';
import { AUTH_STORE_KEY } from '../constant/constant';
import { SessionService } from './session.service';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
    constructor(private injector: Injector,  private toastr: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const sessionService = this.injector.get(SessionService);

        // console.log(request); //--------------------------------

        let accessToken = null;
        let token = localStorage.getItem(AUTH_STORE_KEY).replace(/['"]+/g, '');
        
        if (localStorage.hasOwnProperty(AUTH_STORE_KEY) && token) {
            accessToken = token;
        }
        
        if (this.isInvalidToken(accessToken)) {
            this.logoutV2(sessionService, 'You are not logged in');
            throw new Error(`Access token not found`);
        }
        return next.handle(this.customRequest(request, accessToken)).pipe(tap(
            (event: HttpEvent<any>) => {
                if(event['body']?.['header']?.['responseCode'] === "403"){
                    this.toastr.info('Your session has been expired. Please login again');
                    this.logoutV2(sessionService, 'Your session has been expired. Please login again', true);
                }
                if (event instanceof HttpResponse) {
                    return event;
                }
                return '';
            }),catchError((error: any) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {
                        this.logoutV2(sessionService, 'Your session has been expired. Please login again', true);
                    }
                }
                return throwError(() => { return error });
            }));
        
    }

    isInvalidToken(token: string) {
        return (typeof token === 'undefined' || token === null || token.toString().length === 0);
    }

    customRequest(request: HttpRequest<any>, accessToken: string) {
        const req = request.clone({
            setHeaders: {
                Authorization: accessToken
            }
        });
        return req;
    }

    logoutV2(sessionService: SessionService, logoutMessage: string, hasSessionExpired: boolean = false) {
        const router = this.injector.get(Router);
        sessionService.redirectUrl = router.url;
        sessionService.logoutMessage = logoutMessage;
        sessionService.hasSessionExpired = hasSessionExpired;
        sessionService.logoutV2();
    }
}

