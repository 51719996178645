import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { loginCookieStoreKey, userIdStoreKey, userNameStoreKey, mobileNoStoreKey, roleIdStoreKey, emailStoreKey, branchStoreKey, cspStoreKey, loginOidStoreKey, clusterOidStoreKey, agentClusterOidStoreKey, branchOidStoreKey, cspOidStoreKey } from 'src/app/common/constant/constant';
import { Header } from 'src/app/common/request/base-request';
import { SidebarService } from 'src/app/common/sidebar/sidebar.service';
import { AppStorageService } from 'src/app/login/auth/app-storage.service';
import { AuthService } from 'src/app/login/auth/auth.service';
import { RequestHeader } from 'src/app/login/shared/model/keycloak-user-info/Header/request-header';
import { ResponseHeader } from 'src/app/login/shared/model/keycloak-user-info/Header/response-header';
import { KeycloakUserInfoRequest } from 'src/app/login/shared/model/keycloak-user-info/keycloak-user-info-request';
import { KeycloakUserInfoRequestBody } from 'src/app/login/shared/model/keycloak-user-info/keycloak-user-info-request-body';
import { KeycloakUserInfoResponse } from 'src/app/login/shared/model/keycloak-user-info/keycloak-user-info-response';
import { KeycloakUserInfoService } from 'src/app/login/shared/services/keycloak-user-info.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    // this.authService.redirectOnCallback();
    // console.log('hello');
  }


  
}
