import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lot',
  templateUrl: './lot.component.html',
  styleUrls: ['./lot.component.scss']
})
export class LotComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
