import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Header } from 'src/app/common/request/base-request';
import { AppStorageService } from 'src/app/login/auth/app-storage.service';
import { AuthService } from 'src/app/login/auth/auth.service';
import { ChangingUserPasswordRequest } from 'src/app/shared/models/changing-user-password/changing-user-password-request';
import { ChangingUserPasswordRequestBody } from 'src/app/shared/models/changing-user-password/changing-user-password-request-body';
import { RequisitionRequestHeader } from 'src/app/shared/models/requisition-request-header';
import { ChangingUserPasswordService } from 'src/app/shared/services/changing-user-password.service';
import { language, loginOidStoreKey } from '../constant/constant';

@Component({
  selector: 'app-changing-user-password-dialog',
  templateUrl: './changing-user-password-dialog.component.html',
  styleUrls: ['./changing-user-password-dialog.component.scss']
})
export class ChangingUserPasswordDialogComponent implements OnInit {

  header: Header = new Header();
  serviceName: String;
  statenmentOid: String;
  status: String;
  userName: String;
  locale: String;
  
  changingUserPasswordRequestBody: ChangingUserPasswordRequestBody = new ChangingUserPasswordRequestBody();

  constructor(
    private changingUserPasswordService: ChangingUserPasswordService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private router: Router,
    private _authService: AuthService,
    private _appStorageService: AppStorageService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ChangingUserPasswordDialogComponent>
  ) { 
    let lan = this._appStorageService.getData(language);
    if (lan) {
      this.locale = lan;
      translate.use(lan);
    } else {
      this.locale = 'bn';
      translate.setDefaultLang('bn');
      this._appStorageService.setData(language, 'bn');
    }
   }

  ngOnInit(): void {
    this.userName = this._authService.currentUserName;
    this.changingUserPasswordRequestBody.loginOid = this._appStorageService.getData(loginOidStoreKey);

    this.translate.onLangChange.subscribe((s: { lang: string }) => {
      if (s.lang === 'en') {
        this.locale = 'en';
        this._appStorageService.setData(language, 'en');
      } else {
        this.locale = 'bn';
        this._appStorageService.setData(language, 'bn');
      }
    });
  }

  public closeDialog(){
      this.dialogRef.close({data:'Cancel'});
  }

  cancel(): void {
    this.closeDialog();
  }

  public closeDialogAfterUpdating(){
    this.dialogRef.close('Yes');
}

  changingUserPasswordRequest: ChangingUserPasswordRequest = new ChangingUserPasswordRequest();
  requisitionRequestHeader: RequisitionRequestHeader = new RequisitionRequestHeader();
  

  submitForChangingPassword(){

    if(this.changingUserPasswordRequestBody.newPassword.trim().length < 6){
      if(this.locale === 'bn'){
        this.toastr.error('পাসওয়ার্ড কমপক্ষে ৬ ডিজিটের হতে হবে!');
      }else{
        this.toastr.error('Password must be atleast 6 cherecters!');
      }
      
    }else{
      this.requisitionRequestHeader.requestId = this.header.requestId;
      this.requisitionRequestHeader.requestDateTime = this.header.requestDateTime;
      this.requisitionRequestHeader.requestSource = this.header.requestSource;
      this.requisitionRequestHeader.requestServiceSource = this.header.requestServiceSource;
  
      this.changingUserPasswordRequest.header = this.requisitionRequestHeader;
      this.changingUserPasswordRequest.body = this.changingUserPasswordRequestBody;
  
      this.changingUserPasswordService.changingPassword(this.changingUserPasswordRequest).subscribe(res=> {
         if(res.header.responseCode === '200'){
          if(this.locale === 'bn'){
            this.toastr.success('পাসওয়ার্ড সফলভাবে পরিবর্তন করা হয়েছে!');
          }else{
            this.toastr.success('Password has been Changed Successfully!');
          }
          
          this.cancel();
         }else{
          if(this.locale === 'bn'){
            this.toastr.error('নতুন পাসওয়ার্ড, নিশ্চিতকরণ পাসওয়ার্ডের সদৃশ না');
          }else{
            this.toastr.error('New password does not match with confirmation password');
          }
          
         }
      });
    }
  }

}

