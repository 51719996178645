export const environment = {
  production: false,

  baseURL: 'https://ampere.agranidoer.com/doer-erp/api',
  baseURLForSecondPhase: 'https://ampere.agranidoer.com/doer-erp/api-s',
  reportURL: 'https://ampere.agranidoer.com/doer-erp-report/api',
  uploadImageUrl: 'https://ampere.agranidoer.com/doer-erp/api/v1/file/receipt/download/files/',

  // baseURL: 'http://172.16.6.53:90/doer-erp/api',
  // baseURLForSecondPhase: 'http://172.16.6.53:90/doer-erp/api-s',
  // reportURL: 'http://172.16.6.53:90/doer-erp-report/api',
  // uploadImageUrl: 'http://172.16.6.53:90/doer-erp/api/v1/file/receipt/download/files/',

  projectOid: '44cef489-4e69-4f56-acc5-1eacb2eb499c'
};
