import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinnerConfiguration } from './shared/models/ngx-spinner-configuration';
import { ConstantService } from './shared/services/constant.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  
  title = 'DOER-ERP';


  constructor(
    private translate: TranslateService
  ) { 
    translate.addLangs(['en', 'bn']);
    // translate.setDefaultLang('en');
    // translate.use('en');  
  }

    ngOnInit(): void {
  }

  ngViewAfterInit(){
  }


}
