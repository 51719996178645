import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import { LocalStorageComponent } from './local-storage/local-storage.component';
import { ChangingUserPasswordDialogComponent } from './changing-user-password-dialog/changing-user-password-dialog.component';
import { PdfViewerComponent } from './pdf-viewer/pdfViewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MainShellComponent } from './main-shell/main-shell.component';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpBackend, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [SidebarComponent,
                NavbarComponent,
                LocalStorageComponent,
                ChangingUserPasswordDialogComponent,
                PdfViewerComponent,
                MainShellComponent
                ],
  imports: [
    MatDialogModule,
    SharedModule,
    RouterModule, 
    HttpClientModule, 
    HttpClientJsonpModule,
    NgxExtendedPdfViewerModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule.forRoot(),
    PerfectScrollbarModule,
    ToastrModule.forRoot()
  ],
  exports: [
    SidebarComponent,
    SharedModule,
    NavbarComponent,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    PerfectScrollbarModule,
    ToastrModule,
    
  ]
})
export class CommonLayoutModule { }
