import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ToastrModule } from 'ngx-toastr';
import { CommonLayoutModule } from './common/common-layout.module';
import { LandingPageComponent } from './keycloak/landing-page/landing-page.component';
import { CallbackComponent } from './keycloak/callback/callback.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ClusterComponent } from './pages/cluster/cluster.component';
import { LotComponent } from './pages/lot/lot.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { NgxImageCompressService } from "ngx-alldone-image-compress";
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from './shared/shared.module';
import { CustomPreloadingStrategy } from './common/services/custom-preloading-strategy';
import { TokenInterceptorService } from './common/services/token-interceptor.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function createTranslateLoader(httpBackend: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(httpBackend), './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LandingPageComponent,
    CallbackComponent,
    ClusterComponent,
    LotComponent
  ],
  imports: [
    NgxExtendedPdfViewerModule,
    SharedModule,
    CommonLayoutModule,
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend]
      }
    }),
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['http://localhost:9090/doer-erp/api'],
        // allowedUrls: ['http://172.16.5.73:9090/doer-erp/api'],
        //  allowedUrls: ['https://doererp.celloscope.net/doer-erp/api'],
        // allowedUrls: ['https://ampere.agranidoer.com/doer-erp/api'],
        //  allowedUrls: ['https://ampere.celloscope.net/doer-erp/api'],
        sendAccessToken: true,
      },
    }),
    AppRoutingModule,
  ],
  providers: [
    CustomPreloadingStrategy,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
  },
    NgxImageCompressService],
  bootstrap: [AppComponent]
})
export class AppModule { }
