import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AUTH_STORE_KEY } from 'src/app/common/constant/constant';
import { environment } from 'src/environments/environment';
import { LoginRequest } from '../model/login-request';
import { LoginResponse } from '../model/login-response';
import { LoginTokenResponse } from '../model/login-token-response';
import { api } from 'src/app/common/constant/api';


@Injectable({
  providedIn: 'root'
})
export class UserInfoService {


  constructor(private httpClient: HttpClient) { }
  
    getUserInfo(loginRequest: LoginRequest): Observable<LoginResponse> {
      return this.httpClient.post<LoginResponse>(`${environment.baseURL}${api.getUserInfo}`, loginRequest);
    }
}
