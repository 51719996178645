import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, CanLoad, UrlSegment, Route, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../login/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardGuard implements CanActivate, CanActivateChild, CanLoad {

    constructor(private router: Router, private authService: AuthService) { }

  private check(allowedRoles: string[]): Observable<boolean> {
    let currentRole = this.authService.currentRoleOid;
    if(allowedRoles.includes(currentRole)) {
        return of(true);
    }
    this.router.navigate(['/access-denied']);
    return of(false);
}

canActivate(
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  return this.check(next.data.roles);
}
canActivateChild(
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  return this.check(next.data.roles);
}

canLoad(
  route: Route,
  segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
  return this.check(route.data?.roles);
}
  
}
